/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/

.about {
    @media (max-width: 768px) {
        text-align: center;
    }

    .container {
        position: relative;
        z-index: 10;

        .row {
            .content {
                padding: 30px 30px 30px 0;

                @media (max-width: 1200px) {
                    padding-right: 0;
                }

                h3 {
                    font-weight: 700;
                    font-size: 34px;
                    margin-bottom: 30px;
                }

                p {
                    margin-bottom: 30px;
                }

                .about-btn {
                    padding: 8px 30px 9px 30px;
                    color: rgba(255, 255, 255, 1);
                    border-radius: 50px;
                    transition: 0.3s;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 13px;
                    display: inline-flex;
                    align-items: center;
                    border: 2px solid var(--oksijen-turuncu);

                    &:hover {
                        background: var(--oksijen-turuncu);
                    }

                    i {
                        font-size: 16px;
                        padding-left: 5px;
                    }
                }
            }

            .icon-boxes {
                .icon-box {
                    margin-top: 30px;

                    i {
                        font-size: 40px;
                        color: var(--oksijen-turuncu);
                        margin-bottom: 10px;
                    }

                    h4 {
                        font-size: 20px;
                        font-weight: 700;
                        margin: 0 0 10px 0;
                    }

                    p {
                        font-size: 15px;
                        color: rgba(132, 132, 132, 1);
                    }
                }
            }
        }
    }
}
